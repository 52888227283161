(function ($) {
  $(window).load(function(){
    if ($(window).width() <= 767.98) {
      $(".home .facilities .contentblock").addClass('slider').addClass('owl-carousel');
      $(".home .news .contentblock").addClass('slider').addClass('owl-carousel');
    }

    $('.owl-carousel:not(.slider)').owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: true,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],

      dots: true,

      // drag options
      mouseDrag: false
    })

    $(".slider:not(section.slider)").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 15,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: true,
      navContainer: false,
      navText: ['<i class=\'fas fa-chevron-left\'></i>', '<i class=\'fas fa-chevron-right\'></i>'],

      dots: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 3
        }
      }
    });
  });

  $(document).ready(function(){
    var windowWidth = $(window).width();
    if (windowWidth > 0) {
      $(window).scroll(function(){
        if ($(window).scrollTop() > 0) {
          $('body').addClass('sticky');
          $('.header').addClass('sticky');
        }
        else {
          $('body').removeClass('sticky');
          $('.header').removeClass('sticky');
        }
      });
    }

    $('.js-goto-bottom').on('click', function(event) {
      event.preventDefault();
      var offset = $('.intro').first().offset();
      $("html, body").stop().animate({scrollTop: offset.top}, 500, 'swing');
    });

    $('.footer-botom .footer_link .link').on('click', function(event) {
      event.preventDefault();
      var offset = $('.intro').first().offset();
      $("html, body").stop().animate({scrollTop: offset.top}, 500, 'swing');
    });

    $('a[data-fancybox]').fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close"
      ]
    });
  });
})(jQuery);
